<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
        :headers="headers"
        :items="legals"
        :loading="loading"
        hide-default-footer
        disable-pagination
        class="elevation-1"
    >
      <template v-slot:header.add="">
        <router-link :to="{ name: 'LegalCreate' }">
          <v-avatar color="teal" size="26" rounded>
            <v-icon dark>mdi-plus</v-icon>
          </v-avatar>
        </router-link>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <router-link
              :to="{
              name: 'LegalEdit',
              params: { legalId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar color="red darken-4" size="26" rounded>
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm"/>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import ApiService from "@/core/services/api.service";

export default {
  name: "legals",
  computed: {
    ...mapGetters(["currentUser"]),
    headers() {
      return [
        {text: "Id", align: "start", value: "id", sortable: false},
        {text: this.$i18n.t('common.titleFirstSection'), value: "titleFirstSection", sortable: false},
        {text: this.$i18n.t('common.titleSecondSection'), value: "titleSecondSection", sortable: false},
        {text: this.$i18n.t('common.titleBusinessNote'), value: "titleBusinessNote", sortable: false},
        {text: this.$i18n.t('common.language'), value: "language", sortable: false},
        {text: "", value: "edit", width: "5%", sortable: false},
        {text: "", value: "add", width: "5%", sortable: false}
      ]
    }
  },
  data() {
    return {
      legals: [],
      loading: false,
      loader: null,
      errors: []
    };
  },
  async created() {
    if (!this.currentUser.superAdmin) return;
    this.loading = true;
    this.retrieveLegals();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Legal"}]);
  },
  components: {
    ConfirmDialog
  },
  methods: {

    async delRecord(item) {
      if (
          await this.$refs.confirm.open(
              "Confirm",
              "Are you sure you want to delete legal with id: " + item.id + " ?"
          )
      ) {
        this.deleteLegal(item);
      }
    },
    deleteLegal(item) {
      this.loading = true;
      ApiService.delete(`api/legal/${item.id}`)
          .then(response => {
            this.$log.debug("Legal deleted: ", response);
            this.retrieveLegals();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    retrieveLegals() {
      let params = {};
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/legal", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Legals: ", response.data);
          this.legals = response.data;
          this.loading = false;
          resolve();
        });
      });
    }
  }
};


</script>
<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

tbody tr {
  width: 100%;
}

.filter {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  background-color: aliceblue;
  border: 1px solid brown;
}

.search {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.search-item {
  justify-content: center;
  display: flex;
}

</style>
